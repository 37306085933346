import React from 'react';
import { Button } from "@/global-components/components/ui/button";
import { Spin } from "react-cssfx-loading";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/global-components/components/ui/tooltip"
import { FileType } from "@/global-components/types"
import helpers from '@/global-components/components/helpers';

type ProductStatus = 'unapproved' | 'approved' | 'production';
type ProductActionsProps = {
  userIsSuperuser: boolean;
  productStatus: ProductStatus;
  files: FileType[];
  filesToUpload: File[];
  uploading: boolean;
  classNames: string;
  updateProductStatus: Function;
  updateWithNewFiles: Function;
}
const ProductActions = ({userIsSuperuser, productStatus, files, filesToUpload, uploading, classNames, updateProductStatus, updateWithNewFiles}: ProductActionsProps) => {
  const updateWithNewFilesAction = (): JSX.Element | null => {
    return <Button disabled={uploading} variant="bwconfirm" onClick={() => updateWithNewFiles()}>
            {uploading ? <Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" /> : 'Upload Files'}
          </Button>
  }

  const renderClientProductActions = (): JSX.Element | null => {
    if (productStatus === 'approved') {
      return <div className='flex gap-2'>
              {/* <Button disabled={uploading || process.env.REACT_APP_ENV==='beta'} variant="outlinehighlight" onClick={() => updateProductStatus('unapproved')}>Ask for design consultation</Button> */}
              <Button disabled={uploading || process.env.REACT_APP_ENV==='beta'} variant="bwconfirm" className='pulsate' onClick={() => updateProductStatus('production')}>Allow Production</Button>
              {filesToUpload.length > 0 ? updateWithNewFilesAction() : null}
            </div>
    } else if (productStatus === 'production') {
      return <div className='flex gap-2'>
                {/* ----------- FOR THE MINUTE WE WILL DISABLE THIS UNTIL IT'S CLEAR WHO AND WHEN CAN ALLOW THIS ------------  
                <Button disabled={uploading} variant="destructiveoutline" onClick={() => updateProductStatus('approved')}>Revoke Production Permission</Button>*/}
                {filesToUpload.filter(helpers.filters.isOtherFile)?.length ? updateWithNewFilesAction() : null}
              </div>
    } else {
      return <div className='flex gap-2'>
                <Button disabled={uploading || process.env.REACT_APP_ENV==='beta'} variant="destructiveghost" onClick={() => updateProductStatus('approved')}>Cancel consultation request</Button>
                {filesToUpload.filter(helpers.filters.isOtherFile)?.length ? updateWithNewFilesAction() : null}
              </div>
    }
  }

  const renderSuProductActions = (): JSX.Element | null => {
    if (productStatus === 'approved') {
      return  <div className='flex gap-2'>
                <Button disabled={uploading} variant="destructiveoutline" onClick={() => updateProductStatus('unapproved')}>Revoke Approval</Button>
                <Button disabled={uploading} variant="bwconfirm" className='pulsate' onClick={() => updateProductStatus('production')}>Allow Production</Button>
                {filesToUpload.filter(helpers.filters.isOtherFile)?.length || filesToUpload.filter(helpers.filters.isFileGcode)?.length ? updateWithNewFilesAction() : null}
              </div>
    } else if (productStatus === 'production') {
      return  <div className='flex gap-2'>
                <Button disabled={uploading} variant="destructiveoutline" onClick={() => updateProductStatus('unapproved')}>Revoke Approval</Button>
                <Button disabled={uploading} variant="destructiveoutline" onClick={() => updateProductStatus('approved')}>Revoke Production Permission</Button>
                {filesToUpload.filter(helpers.filters.isOtherFile)?.length || filesToUpload.filter(helpers.filters.isFileGcode)?.length ? updateWithNewFilesAction() : null}
              </div>
    } else {
      if (!files.filter(helpers.filters.isFileGcode)?.length) {
        return  <div className='flex gap-2'>
                  {filesToUpload.filter(helpers.filters.isOtherFile)?.length || filesToUpload.filter(helpers.filters.isFileGcode)?.length ? updateWithNewFilesAction() : null}
                  <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span>
                        <Button variant="bwconfirm" 
                          disabled
                          >
                            Approve
                        </Button>
                      </span>
                    </TooltipTrigger>
                      <TooltipContent className='bg-pale-sage text-bw-green'>
                        <p>Please add the GCODE in order to approve the product</p>
                      </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
      } else {
        return  <div className='flex gap-2'>
                  <Button variant="bwconfirm" onClick={() => updateProductStatus('approved')}>{uploading ? <Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" /> : 'Approve'}</Button>
                  {filesToUpload.filter(helpers.filters.isOtherFile)?.length || filesToUpload.filter(helpers.filters.isFileGcode)?.length ? updateWithNewFilesAction() : null}
                </div>
      }
    }
  } 
  return (
    <div className={`product-actions flex gap-2 z-40 ${classNames}`}>
      {userIsSuperuser ? 
        renderSuProductActions()
      : renderClientProductActions()}
    </div>
  )
}

export default ProductActions;