import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/global-components/components/ui/table"
import { ProductStatus, ProductType, User } from '@/global-components/types'
import ProductDelete from '@/views/product/productDelete/ProductDelete'
import { Badge } from '@/global-components/components/ui/badge';
import helpers from '@/global-components/components/helpers'

interface ProductsListProps {
  filteredProducts: ProductType[]
  user: User | null
  goToProduct: Function
  productDeleted: Function
}

const ProductsList = ({filteredProducts, goToProduct, productDeleted, user}: ProductsListProps) => {
  return (
    <Table>
      <TableHeader className='text-xs text-bw-green'>
        <TableRow className='border-bw-pale-sage border-b'>
          <TableHead className="text-bw-green/30 w-2/12">Product name</TableHead>
          <TableHead className="text-bw-green/30 w-2/12">Project</TableHead>
          <TableHead className="text-bw-green/30 w-1/12">User</TableHead>
          <TableHead className="text-bw-green/30 w-3/12">Organisations</TableHead>
          <TableHead className="text-right text-bw-green/30 w-2/12">Created on</TableHead>
          <TableHead className="text-bw-green/30 w-1/12">Status</TableHead>
          <TableHead className="text-bw-green/30 w-1/12"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredProducts.map((product: ProductType, index: number) => (
          <TableRow key={product.productId} 
            className='submission border-b cursor-pointer border-bw-pale-sage rounded-md h-4 pl-4 hover:bg-ui-table-row-hover'>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)} className="font-bold relative">
              <div className='flex items-center'>
                {product.productTitle}
              </div>
            </TableCell>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)}>
              {product.groupId.groupTitle}
            </TableCell>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)} >
              {product.createdBy?.userId === user?.userId ? 
                'You'
                : product.createdBy?.firstName + ' ' + product.createdBy?.lastName
              }
            </TableCell>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)} >
              {product.organisations?.map(organisation => organisation.name).join(', ')}
            </TableCell>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)}  className="text-right">{helpers.formatDateToDDMMYY(product.createdAt)}</TableCell>
            <TableCell onClick={() => goToProduct(product.productVersion[0].reference)} ><Badge variant={product.status.toLowerCase() as ProductStatus}>{product?.status}</Badge></TableCell>
            <TableCell className='text-right'>
              <ProductDelete productId={product.productVersion[0].reference} buttonVariant='destructiveghost' productDeleted={productDeleted}/>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ProductsList