// React imports
import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';

// Context and API imports
import api from '../../api/bw-api';
import useUserStore from '@/context/useUserStore';

// Type imports
import { User, ProductType, ProductGroup, ProductStatus, ProductsViewType, Organisation } from '@/global-components/types'

// Component imports
import { useToast } from "@/global-components/components/ui/use-toast"
import ProductPreview from './ProductPreview';
import GroupPreview from './GroupPreview';
import SubmitNewProduct from '../submitNewProduct/SubmitNewProduct';

import { Tabs,  TabsList, TabsTrigger } from "@/global-components/components/ui/tabs"

// Styles
import './UserProducts.scss'; 
import helpers from '@/global-components/components/helpers';
import { Badge } from '@/global-components/components/ui/badge';
import ProductDelete from '@/views/product/productDelete/ProductDelete';
import Combobox from '@/global-components/components/combobox/Combobox';
import { Button } from '@/global-components/components/ui/button';
import { Grip, List } from 'lucide-react';
import { Switch } from '@/global-components/components/ui/switch';
import ProductsList from '@/components/ProductsList';
import ProductsHeader from '@/components/ProductsHeader';
import OrganisationsProducts from './OrganisationsProducts';
import useProductStore from '@/context/useProductStore';

interface UserProductsProps {
  showStaffProducts?: boolean
}

const ProductStatusFilterItems: {value: ProductStatus}[] = [{value: 'unapproved'}, {value: 'approved'}, {value: 'production'}]

const UserProducts = (props: UserProductsProps) => {
  const { user, userPreferences, setUserPreferences } = useUserStore()
  const { toast } = useToast()

  const [statusFilter, setStatusFilter] = useState<string>(localStorage.getItem('statusFilter') || '');
  const [groupFilter, setGroupFilter] = useState<string>(localStorage.getItem('groupFilter') || '');
  const [organisations, setOrgsanisations] = useState<Organisation[]>([])
  const [organisationFilter, setOrganisationFilter] = useState<string>(localStorage.getItem('organisationFilter') || '')
  const organisationsQuery = useQuery(api.organisations.queries.GET_ORGANISATIONS);

  useEffect(() => {
    if (organisationsQuery.data) {
      setOrgsanisations(organisationsQuery.data.organisations)
    }
  }, [organisationsQuery])

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reload = queryParams.get('reload');
  const deletedProductId = queryParams.get('deletedProductId');
  const [loading, setLoading] = useState<boolean>(true)
  const [deletingProductWithId, setDeletingProductWithId] = useState<string | null>(null)

  const [gridColumns, setGridColumns] = useState<string>('grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8');
  

  // const [products, setProducts] = useState<ProductType[]>([]);
  const { products, setProducts, groups, setGroups } = useProductStore()

  const [groupByProjects, setGroupByProjects] = useState<boolean>(false)
  const [productsViewType, setProductsViewType] = useState<ProductsViewType>('GRID')
  const [updatingUserPreferences, setUpdatingUserPreferences] = useState<boolean>(false)

  const [updateUserPreferences] = useMutation(api.user.mutations.UPDATE_USER_PREFERENCES)

  const updateGroupByProjects = (value: boolean) => {
    setUpdatingUserPreferences(true)
    setGroupByProjects(value)
    updateUserPreferences({
      variables: {
        sortHomeByProjects: value
      }
    }).then((result: any) => {
      setUserPreferences(result.data.updateUserPreferences.updatedUserPreferences)
    }).catch((error: any) => {
      helpers.log(error)
    }).finally(() => {
      setUpdatingUserPreferences(false)
    })
  } 

  const updateProductsViewType = (value: ProductsViewType) => {
    setUpdatingUserPreferences(true)
    setProductsViewType(value)
    updateUserPreferences({
      variables: {
        productsViewType: value
      }
    }).then((result: any) => {
      setUserPreferences(result.data.updateUserPreferences.updatedUserPreferences)
    })
      .catch((error: any) => helpers.log(error))
      .finally(() => {
        setUpdatingUserPreferences(false)
      })    
  }

  useEffect(() => {
    if (userPreferences) {
      setGroupByProjects(userPreferences.sortHomeByProjects);
      setProductsViewType(userPreferences.productsViewType);
    }
  }, [userPreferences]);


  const getAllProductsForUserQuery = useQuery(api.products.queries.GET_PRODUCTS_FOR_USER, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: user?.userId,
      includeMessages: true,
      includeFiles: true
    },
    skip: !user
  });

  const getAllGroupsForUserQuery = useQuery(api.products.queries.GET_GROUPS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
  })

  const sortProductsByStatus = (a: any, b: any): number => {
    const order: { [key: string]: number } = {
      'production': 1,
      'approved': 2,
      'unapproved': 3,
    };
  
    return order[a.status.toLowerCase()] - order[b.status.toLowerCase()];
  };

  const showOnlyLatestVersion = (product: any): boolean => {
    return product.latestVersion
  }

  const productsInGroup = (group: ProductGroup): ProductType[] => {
    return products.filter((product: ProductType) => product.groupId?.groupId === group.groupId);
  }

  const isUsersGroup = (group: ProductGroup, index: number, array: ProductGroup[]): boolean => {
    return group.createdBy.userId === user?.userId
  }

  const goToProduct = (productRef: string) => {
    navigate('/product/' + productRef);
  }

  const clearFilters = () => {
    setGroupFilter('')
    setStatusFilter('')
    setOrganisationFilter('')
  }

  const filterByGroupId = (product: ProductType) => {
    if (groupFilter === '') {
      return true
    } else {
      return product.groupId.groupId === groupFilter;
    }
  }

  const filterByOrganisationId = (product: ProductType) => {
    if (organisationFilter === '') {
      return true
    } else {
      return product.organisations?.find(org => org.organisationId === organisationFilter)
    }
  }

  const filterByStatus = (product: ProductType) => {
    if (statusFilter === '') {
      return true
    } else {
      return product.status.toLowerCase() === statusFilter;
    }
  }

  useEffect(() => {
    localStorage.setItem('statusFilter', statusFilter);
    localStorage.setItem('groupFilter', groupFilter);
    localStorage.setItem('organisationFilter', organisationFilter)
  }, [statusFilter, groupFilter, organisationFilter]);

  useEffect(() => {
    setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    const handleResize = () => {
      setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (reload) {
      helpers.log('reloading products')
      setLoading(true)
      getAllProductsForUserQuery.refetch();
    }

    if (deletedProductId) {
      setDeletingProductWithId(deletedProductId)
    }
  }, [location]);

  useEffect(() => {
    if (getAllProductsForUserQuery.loading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [getAllProductsForUserQuery.loading])

  useEffect(() => {
    if (getAllProductsForUserQuery.data?.productsForUser)  {
      const sortedProducts: any[] = [...getAllProductsForUserQuery.data?.productsForUser].sort(sortProductsByStatus);
      const filteredProducts: ProductType[] = user?.isSuperuser ?
        sortedProducts.filter((product: any) => product.createdBy.userId === user?.userId)
          .filter(showOnlyLatestVersion)
        :
        sortedProducts.filter((product: any) => product.status.toLowerCase() === 'approved' || product.status.toLowerCase() === 'production')
          .filter(showOnlyLatestVersion)

      setProducts(filteredProducts)

    }
  }, [getAllProductsForUserQuery.data])

  useEffect(() => {
    if (getAllProductsForUserQuery.error) {
      toast({
        title: "Loading Problem",
        description: "We're really sorry, but there was a problem loading data form the server. Please try again later or contact software@batch.works.",
        variant: 'destructive'
      })
    }
  }, [getAllProductsForUserQuery.error])

  useEffect(() => {
    if (getAllGroupsForUserQuery.data?.groups) {
      setGroups(getAllGroupsForUserQuery.data.groups)
    }
  }, [getAllGroupsForUserQuery])

  // if (products.length === 0) {
  //   <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
  //     <NewProduct />  
  //   </div>
  // }

  return (
    <div>
      <ProductsHeader
        groupFilter={groupFilter}
        statusFilter={statusFilter}
        organisationFilter={organisationFilter}
        groups={groups}
        organisations={organisations}
        groupByProjects={groupByProjects}
        productsViewType={productsViewType}
        clearFilters={() => {
          setGroupFilter('');
          setStatusFilter('');
          setOrganisationFilter('');
        }}
        setStatusFilter={setStatusFilter}
        setGroupFilter={setGroupFilter}
        setOrganisationFilter={setOrganisationFilter}
        updateGroupByProjects={updateGroupByProjects}
        updateProductsViewType={setProductsViewType}
      />
      {productsViewType === 'GRID'
        ? <div> 
          <div className={`text-xs text-bw-green/30 font-bold pl-4 mb-1`}>Active</div>
          {groupByProjects ?
            <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
              {groups.filter(isUsersGroup).map((group: ProductGroup, index: number) => (
                <GroupPreview
                  key={index}
                  group={group}
                  products={productsInGroup(group)}
                />
              ))}
            </div>
            :
            <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
              {products.filter(filterByGroupId).filter(filterByStatus).filter(filterByOrganisationId).map((product: any, index: number) => (
                <ProductPreview 
                  key={index}
                  product={product}
                  callback={() => getAllProductsForUserQuery.refetch()}
                  navigate={navigate} 
                  index={index} 
                />
              ))}
              <NewProduct />  
            </div>
          }
          <OrganisationsProducts groupByProjects={groupByProjects} />
        </div>
        : <div>
          <ProductsList 
            filteredProducts={products.filter(filterByGroupId).filter(filterByStatus).filter(filterByOrganisationId)}
            productDeleted={() => getAllGroupsForUserQuery.refetch()}
            goToProduct={goToProduct}
            user={user}
          />
          <OrganisationsProducts groupByProjects={groupByProjects} productsViewType={productsViewType} />
        </div>
        }
    </div>
  )
}

export default UserProducts;


const NewProduct = () => {
  return (
    <SubmitNewProduct fromCard />
  )
}