// React imports
import { useState } from 'react';
import { useMutation } from '@apollo/client';

// Context and API imports
import api from '../../../api/bw-api';

// Component imports
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog";
import { Button } from "@/global-components/components/ui/button";
import { useToast } from "@/global-components/components/ui/use-toast";
import { XCircle } from "lucide-react";
import { Spin } from 'react-cssfx-loading';


const ProductDelete = (props: any) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteProduct, mutationResults] = useMutation(api.products.mutations.DELETE_PRODUCT);
  const { toast } = useToast()

  const confirmDelete = () => {
    setLoading(true)
    console.log('ref ', props.reference)
    deleteProduct({ variables: { reference: props.reference }})
        .then((result : any) => {
          console.log(result)
          if(result.data.deleteProduct.success) {
            toast({
              title: "Product deleted successfully",
              variant: 'success',
              duration: 2000,
            })
            if(props.callback) {
              props.callback();
            }
            setLoading(false)
            setOpen(false)
          } else {
            toast({
              title: "Couldnt delete product",
              description: result.data.deleteProduct.errors ? result.data.deleteProduct.errors : null,
              variant: 'destructive',
              duration: 5000,
            })
            setLoading(false)
            setOpen(false)
          }
        })
        .catch((error : any) => { })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button ref={props.forwardRef} variant={props.buttonVariant ? props.buttonVariant : 'destructiveoutline'} size={props.buttonSize ? props.buttonSize : 'icon'}><XCircle className='h-4 w-4' /></Button>
      </DialogTrigger>
      <DialogContent className='max-w-l' ref={props.forwardRef}>
        <DialogHeader>
          <DialogTitle>Delete Product</DialogTitle> 
        </DialogHeader>
        <div>This will delete this product and all of its versions. <b>This cannot be undone.</b></div>
        <DialogFooter>
          <Button variant="bwsecondary" onClick={() => setOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={confirmDelete} disabled={loading}>
            {loading ? <Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" /> : 'Delete'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ProductDelete;