import React, { useState, useEffect } from "react";
import {
  Building,
  Check,
  ChevronDown,
  User2,
  Plus,
  PlusCircle,
  X,
  XCircle,
  Loader,
} from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/global-components/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandSeparator,
  CommandItem,
} from "@/global-components/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover";
import { useQuery } from "@apollo/client";
import api from "@/api/bw-api";
import { Organisation } from "@/global-components/types";
import { useNavigate } from "react-router-dom";

type OrganisationSelectProps = {
  productOrganisations: Organisation[];
  toggleOrganisation: Function;
  productLoading: boolean;
  buttonPositioningClasses?: string;
  access: boolean;
  updatingOrgIds: string[];
};

const OrganisationSelect = ({
  productOrganisations,
  toggleOrganisation,
  productLoading,
  buttonPositioningClasses,
  access,
  updatingOrgIds = [],
}: OrganisationSelectProps): JSX.Element | null => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [updatingRights, setUpdatingRights] = useState<boolean>(false);
  const organisationsQuery = useQuery(
    api.organisations.queries.GET_ORGANISATIONS
  );

  useEffect(() => {
    setValue(productOrganisations.length);
  }, [productOrganisations]);

  useEffect(() => {
    setOrganisations(organisationsQuery.data?.organisations);
  }, [organisationsQuery?.data]);

  useEffect(() => {
    //setUpdatingRights(productLoading)
  }, [productLoading]);

  return (
    <div className="">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={!access}
            aria-expanded={open}
            className={`w-[350px] justify-between bg-transparent pl-3 pr-2 ${buttonPositioningClasses}`}
          >
            <div className="flex gap-2 items-center">
              <Building className="h-4 w-4" />
              {access
                ? value
                  ? "Available to " + value + " organisations"
                  : "Available to you only"
                : "Shared with you"}
            </div>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={open ? "w-[350px] p-0" : "w-[300px] p-0 bg-transparent"}
        >
          <div className="flex flex-col gap-1 p-4">
            {organisations?.map((organisation) => (
              <div
                key={organisation.organisationId}
                className="items-center group flex justify-between text-bw-green"
              >
                <div className="font-medium flex text-sm items-center gap-2">
                  {organisation.name}
                  <div className="opacity-50 text-xs flex items-center">
                    {" "}
                    <User2 className="h-3 w-3" strokeWidth={3} />{" "}
                    {organisation.memberships?.length}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {productOrganisations.find(
                    (grpOrg) =>
                      grpOrg.organisationId === organisation.organisationId
                  ) ? (
                    <Button
                      variant="destructive"
                      className="flex items-center gap-1"
                      disabled={
                        updatingOrgIds.find(
                          (id: string) => id === organisation.organisationId
                        )
                          ? true
                          : false
                      }
                      size="sm"
                      onClick={() =>
                        toggleOrganisation(organisation.organisationId)
                      }
                    >
                      {updatingOrgIds.find(
                        (id: string) => id === organisation.organisationId
                      ) && <Loader className="h-4 w-4 animate-spin" />}
                      Revoke
                    </Button>
                  ) : (
                    <Button
                      variant="bwconfirm"
                      size="sm"
                      disabled={
                        updatingOrgIds.find(
                          (id: string) => id === organisation.organisationId
                        )
                          ? true
                          : false
                      }
                      onClick={() =>
                        toggleOrganisation(organisation.organisationId)
                      }
                    >
                      {updatingOrgIds.find(
                        (id: string) => id === organisation.organisationId
                      ) && <Loader className="h-4 w-4 animate-spin" />}
                      Share
                    </Button>
                  )}
                </div>
              </div>
            ))}

            <Button
              variant="bwsecondary"
              size="sm"
              className="w-full text-center items-center mt-3"
              onClick={() => navigate("/account")}
            >
              <Plus className="h-4 w-4 mr-1" /> Create New Organisation
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default OrganisationSelect;
