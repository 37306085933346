import React, { useEffect, useState } from 'react';
import useUserStore from '@/context/useUserStore';
import { ProductsViewType, User, UserPreferences } from '@/global-components/types'
import helpers from "@/global-components/components/helpers"
import { useQuery, useMutation } from '@apollo/client';
import './Home.scss';
import UserProducts from '@/components/userProducts/UserProducts'
import OrganisationsProducts from '@/components/userProducts/OrganisationsProducts';

import api from '../../api/bw-api';
import FindAnything from '@/components/FindAnything';


const Home = () =>  {
  const { user, loggedIn, userPreferences, setUserPreferences } = useUserStore();
  const [firstName, setFirstName] = useState<string>('')

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user?.firstName)
    }
  }, [user?.firstName])

  return (
    <div className='view home'>
      <UserProducts />
      {/* <OrganisationsProducts />   */}
    </div>
  )
  // }
}

export default Home;