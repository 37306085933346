import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import api from '@/api/bw-api'
import { FileType, FilamentType, EmissionsType } from '@/global-components/types'
import { Select, SelectTrigger, SelectItem, SelectContent, SelectValue } from '@/global-components/components/ui/select'
import Filament from '@/global-components/components/bw/filament/Filament'
import { Input } from '@/global-components/components/ui/input'
import { Spin } from 'react-cssfx-loading'
import helpers from '@/global-components/components/helpers'


interface EmissionsDataProps {
  files: FileType[]
}

const EmissionsData = ({files}: EmissionsDataProps) => {
  const [activeFilamentId, setActiveFilamentId] = useState<string | undefined>('1')
  const [quantity, setQuantity] = useState<number>(1)
  const [gcodeFiles, setGcodeFiles] = useState<FileType[]>([])
  const [activeGcodeId, setActiveGcodeId] = useState<string | undefined>(undefined)
  const [emissions, setEmissions] = useState<EmissionsType | undefined>(undefined)
  const [materialPercentage, setMaterialPercentage] = useState<number>(0)
  const [materialTransportPercentage, setMaterialTransportPercentage] = useState<number>(0)
  const [printingPercentage, setPrintingPercentage] = useState<number>(0)
  const filamentsQuery = useQuery(api.printers.queries.GET_AVAILABLE_FILAMENTS)
  const emissionsQuery = useQuery(api.products.queries.GET_EMISSIONS_FOR_FILE, {
    variables: {
      fileId: activeGcodeId,
      filamentId: activeFilamentId,
      factoryId: 1,
      quantity: quantity
    },
    skip: activeGcodeId === undefined
  })

  useEffect(() => {
    const _gcodes: FileType[] = files.filter(helpers.filters.isFileGcode)
    setGcodeFiles(_gcodes)
    setActiveGcodeId(_gcodes[0].fileId)
  }, [files])

  useEffect(() => {
    if (activeGcodeId) {
      emissionsQuery.refetch()
    }
  }, [activeGcodeId, quantity, activeFilamentId])

  useEffect(() => {
    const emissionsQueryData = emissionsQuery.data?.emissionsForGcode
    if (emissionsQueryData) {
      setEmissions(emissionsQueryData)
      calculatePercentages(emissionsQueryData)
    }
  }, [emissionsQuery.data])

  const calculatePercentages = (data: EmissionsType) => {
    const materialPercentage: number = Number((data.material / data.total * 100).toFixed(3))
    const materialTransportPercentage: number = Number((data.materialTransport / data.total * 100).toFixed(3))
    const printingPercentage: number = Number((data.printing / data.total * 100).toFixed(3))
    console.log('material % ', materialPercentage)
    console.log('material transport % ', materialTransportPercentage)
    console.log('printing % ', printingPercentage)
    setMaterialPercentage(materialPercentage)
    setMaterialTransportPercentage(materialTransportPercentage)
    setPrintingPercentage(printingPercentage)
  }

  return (
    <div className=''>
      <div className='settings flex items-center gap-1'>
        <Select value={activeGcodeId} onValueChange={(value: string) => {
          setActiveGcodeId(value)
        }}>
          <SelectTrigger className="w-full truncate">
            <SelectValue placeholder="Please select a GCode" className='truncate max-w-full' />
          </SelectTrigger>
          <SelectContent>
            {gcodeFiles.map(gcode => {
              return <SelectItem value={gcode.fileId}>{gcode.fileName}</SelectItem>
            })}
          </SelectContent>
        </Select>
        <Select value={activeFilamentId ? activeFilamentId : ''} onValueChange={(value) => {
          setActiveFilamentId(value === 'reset' ? undefined : value)
        }}>
          <SelectTrigger className="w-full" tabIndex={19}>
            <SelectValue placeholder="Please select a Material" />
          </SelectTrigger>
          <SelectContent className=' max-h-64'>
            {filamentsQuery.data?.filament.map((filament: FilamentType, index: number) => (
              <SelectItem key={index} value={filament.filamentId} className='flex' onClick={() => {
                if (activeFilamentId === filament.filamentId) {
                  setActiveFilamentId(undefined)
                }
              }}>
                <div className='flex items-center gap-2'><Filament color={filament.colour?.hexCode || '#333333'} active={true} small={true} /> {filament.material.displayName} - {filament.colour.displayName}</div>
              </SelectItem>
            ))}
            <SelectItem key={'reset'} value={'reset'}>
              <div className='flex items-center gap-2'>None</div>
            </SelectItem>
          </SelectContent>
        </Select>

        <Select value="ssf">
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Please select a Location" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ssf">BW East London</SelectItem>
          </SelectContent>
        </Select>

        <Input 
          type='number' 
          value={quantity} 
          placeholder='Set Quantity' 
          onChange={e => setQuantity(Number(e.target.value))}
          className='basis-2/6'/>
      </div>
      <div className='results flex justify-center items-center w-full pt-8'>
          {(emissions && !emissionsQuery.loading) ?
            <div className='data w-full flex flex-col gap-2'> 
              <div className='graph flex w-full text-xs font-bold'>
                <div className={`material relative min-w-4`} style={{flexBasis: materialPercentage + '%'}}>
                  <div className='value absolute -top-5 left-0 text-nowrap pl-2'>Material: {(emissions.material * 1000).toFixed(2)} g</div>
                  <div className={`bar bg-bw-green rounded-l-full h-4 w-full`}></div>
                </div>

                <div className={`material relative min-w-4`} style={{flexBasis: materialTransportPercentage + '%'}}>
                <div className='value absolute top-5 right-0 text-nowrap pr-2 text-ui-info-rose'>Material Transport: {(emissions.materialTransport * 1000).toFixed(2)} g</div>
                  <div className={`bar bg-ui-info-rose h-4 w-full`}></div>
                </div>

                <div className={`printing relative min-w-4`} style={{flexBasis: printingPercentage + '%'}}>
                  <div className='value absolute -top-5 right-0 text-nowrap pr-2 text-ui-info-purple'>Printing: {(emissions.printing * 1000).toFixed(2)} g</div>
                  <div className={`bar bg-ui-info-purple rounded-r-full h-4 w-full`}></div>
                </div>
              </div>
              <div className='total text-xl px-2'>
                {(emissions.total * 1000).toFixed(2)} g CO2e
              </div>
            </div>
            :
            <Spin className="h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
          }
      </div>
    </div>
  )
}

export default EmissionsData
