import DownloadButton from '@/global-components/components/bw/DownloadButton'
import { Button } from '@/global-components/components/ui/button'
import { FileType, ProductType } from '@/global-components/types'
import { Eye, Minus, MinusCircle, XCircle } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { useToast } from "@/global-components/components/ui/use-toast";
import { useMutation, useQuery } from '@apollo/client';
import api from '@/api/bw-api';
import FileTag, { FileExtensionType } from '@/global-components/components/bw/FileTag'
import helpers from '@/global-components/components/helpers'
import usePreviewStore from '@/context/usePreviewStore'

interface FileInfoProps {
  file: FileType
  product: ProductType
  callback?: Function
  updating?: boolean
  removeFileFromPart?: Function
  previewImage?: Boolean
  inPart?: Boolean
  noEdit?: boolean
  viewOnly?: boolean
  view?: Function
}

const FileInfo = ({file, product, callback=()=>null, view, removeFileFromPart=()=>null, previewImage=false, inPart=false, noEdit=false, updating=false, viewOnly=false}: FileInfoProps) => {
  const { toast } = useToast()
  const { fileIdPreviewed } = usePreviewStore()
  const [removeFileFromProductMutation, { loading: removingFile }] = useMutation(api.products.mutations.REMOVE_FILE_FROM_PRODUCT)
  const [removing, setRemoving] = useState<boolean>(false)

  useEffect(() => {
    console.log('file id previewded ', fileIdPreviewed)
  }, [fileIdPreviewed, file])
  const deleteFile = (file: any) => {
    removeFileFromProductMutation({variables: {fileId: file.fileId, productId: product.productId}})
      .then((result: any) => {
        if (result.data.removeFileFromProduct.success) {
          callback(file.fileId)
          toast({
            title: 'Removed filed successfully from product',
            variant: 'success',
            duration: 3000,
            description: file.fileName + ' deleted successfully.' 
          })
        }
      })
      .catch((error : any) => { 
        toast({
          title: 'Remove failed',
          variant: 'destructive',
          duration: 5000,
          description: "We're sorry, but unfortunately '" + file.fileName + "' could not be deleted. Please try again or contact software@batch.works, or message staff from within the product.",
        })
        
      })
  }

  return (
    <div className={`file-preview relative max-w-full flex justify-between items-center rounded-md border border-bw-green/10 hover:border-bw-green/20 bg-white px-4 py-3 font-mono text-sm group/file ${updating && 'opacity-30'}`}
      key={file.fileId}>
      {previewImage ? 
        <div className='flex items-center gap-2 max-w-full shrink relative'>
          <FileTag type={helpers.extractFileTypeFromFilename(file.fileName) as FileExtensionType} />
          <div className='h-8 w-8 p-0.5 rounded-sm border border-bw-grey'>
            <div className='w-full h-full bg-cover bg-no-repeat rounded-xs' style={{backgroundImage: 'url(' + file.presignedUrl + ')'}}></div>
          </div>
          <div className='whitespace-nowrap truncate'>{file.fileName}</div>
        </div>
        : <div className='flex items-center gap-2 max-w-full shrink grow-0 basis-auto relative'>
            <FileTag type={helpers.extractFileTypeFromFilename(file.fileName) as FileExtensionType} />
            <div className='shrink truncate'>{file.fileName}</div>
          </div>
      }
      <div className='flex items-center absolute right-2 bg-white gap-2 opacity-0 pointer-events-none group-hover/file:opacity-100 group-hover/file:pointer-events-auto'>
        {!noEdit &&
          (!inPart 
            ? <Button className={`${viewOnly && 'hidden'}`} variant='destructiveoutline' size='smallicon' onClick={() => deleteFile(file)}><XCircle className='h-4 w-4' /></Button>
            : <Button className={`${viewOnly && 'hidden'}`} variant='warnoutline' title='Remove file from part' size='smallicon' onClick={() => removeFileFromPart(file.fileId)}><Minus className='h-4 w-4' /></Button>
          )
        }
        <DownloadButton url={file.presignedUrl} name={file.fileName}/>
        <Button variant='outline' className={`${noEdit && 'hidden'}`} size='smallicon' onClick={() => view ? view(file.fileId) : null}>
          <Eye className='h-4 w-4' />
        </Button>
      </div>
      {fileIdPreviewed === file.fileId &&
        <div className='absolute flex items-center justify-center w-1 h-full top-0 right-7 group-hover/file:opacity-0'>
          <div className='h-1 w-1 rounded-full bg-bw-green/70'></div>
        </div>
      }
    </div>
  )
}

export default FileInfo