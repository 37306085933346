import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from "@/global-components/components/ui/use-toast"
import { useNavigate, useLocation, useParams } from "react-router-dom";

import api from '../../api/bw-api';
import { ProductGroup, ProductType } from '@/global-components/types';
import ProductPreview from '@/components/userProducts/ProductPreview';
import OrganisationSelect from '@/global-components/components/bw/organisationSelect/OrganisationSelect';
import helpers from '@/global-components/components/helpers';
import { Folder } from 'lucide-react';
import useUserStore from '@/context/useUserStore';

const Project = () => {
  const { user } = useUserStore()
  const { toast } = useToast()
  const { groupRef } = useParams()
  const navigate = useNavigate()

  const [gridColumns, setGridColumns] = useState<string>('grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8');
  const [productsOfGroup, setProductsOfGroup] = useState<ProductType[]>([])
  const [group, setGroup] = useState<ProductGroup | null>(null)
  const [isSharedWithYou, setIsSharedWithYou] = useState<boolean>(false)

  const [updatingOrgIds, setUpdatingOrgIds] = useState<string[]>([])

  const [updateGroup] = useMutation(api.products.mutations.UPDATE_GROUP);
  const getProductsOfGroup = useQuery(api.products.queries.GET_PRODUCTS_OF_GROUP, {
    variables: {
      groupId: groupRef
    },
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    skip: !groupRef
  })

  const getGroup = useQuery(api.products.queries.GET_GROUP, {
    variables: {
      groupId: groupRef
    },
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    skip: !groupRef
  })

  useEffect(() => {
    setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    const handleResize = () => {
      setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!getGroup.data) return
    if (getGroup.data.group) {
      setGroup(getGroup.data.group)
      if (getGroup.data.group.createdBy !== user?.userId) {
        setIsSharedWithYou(true)
      } else {
        setIsSharedWithYou(false)
      }
    } else {
      helpers.log(getGroup.data.error)
      toast({
        title: "Loading Problem",
        description: "We're really sorry, but there was a problem loading data form the server. Please try again later or contact software@batch.works.",
        variant: 'destructive',
        duration: 5000,
      })
    }
  }, [getGroup])

  useEffect(() => {
    if (!getProductsOfGroup.data) return
    if (getProductsOfGroup.data) {
      if(getProductsOfGroup.data.productsOfGroup) {
        setProductsOfGroup(getProductsOfGroup.data.productsOfGroup)
      } else {
        toast({
          title: "No Access",
          variant: 'destructive',
          duration: 5000,
        })
        navigate('/')
      }
    } else {
      helpers.log(getProductsOfGroup.data.error)
      toast({
        title: "Loading Problem",
        description: "We're really sorry, but there was a problem loading data form the server. Please try again later or contact software@batch.works.",
        variant: 'destructive',
        duration: 5000,
      })
    }
  }, [getProductsOfGroup])

  const toggleOrganisationOnGroup = (id: string) => {
    setUpdatingOrgIds([...updatingOrgIds, id])
    updateGroup({variables: {groupId: group?.groupId, addToOrganisationId: id}})
    .then((result: any) => {
      if (result.data.updateGroup.success) {
        setUpdatingOrgIds(updatingOrgIds.filter((_id: string) => _id !== id))
        toast({
          title: "Group now available to organisation",
          variant: "success",
          duration: 2000
        })
        getGroup.refetch()
      } else {
        setUpdatingOrgIds(updatingOrgIds.filter((_id: string) => _id !== id))
        toast({
          title: "Something went wrong",
          variant: "destructive",
          duration: 2000
        })
      }
    })
  }

  return (
    <div className='view project'>
      <div className='flex justify-between mb-4'>
        <h1 className='text-2xl text-bw-green flex gap-2 items-center mb-4'>
          <Folder className='h-6 w-6' />{group?.groupTitle} {isSharedWithYou && <span className='opacity-30'>(Shared with you)</span>}
        </h1>
        <OrganisationSelect 
          productOrganisations={group?.organisations ? group.organisations : []} 
          toggleOrganisation={toggleOrganisationOnGroup} 
          productLoading={getGroup.loading}
          updatingOrgIds={updatingOrgIds}
          access={user?.userId === group?.createdBy.userId}/>
      </div>
      <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
        {productsOfGroup.map((product: any, index: number) => (
          <ProductPreview 
            key={index} 
            product={product} 
            navigate={navigate} 
            index={index} 
          />
        ))}
      </div>
    </div>
  )
}

export default Project