import React, { useState, useEffect } from 'react'
import { Check, ChevronsUpDown } from "lucide-react"
 
import { cn } from "@/lib/utils"
import { Button } from "@/global-components/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/global-components/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import { Input } from '../ui/input'

type Item = {
  name?: string;
  colour?: string;
  value: any;
}
type ComboboxProps = {
  title: string;
  titleIcon?: JSX.Element;
  wide?: boolean;
  capitalise?: boolean;
  items: Item[];
  className?: string;
  buttonClassName?: string;
  trigger?: boolean;
  value: any;
  selectCallback: Function;
}

const Combobox = (props: ComboboxProps) => {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(props.value ? props.value : '');
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  props.items.forEach((item: Item) => {
    if (!item.name) {
      item.name = item.value;
    }
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value])
 
  return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				{props.trigger ? (
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className={`${props.buttonClassName}`}
					>
              {props.titleIcon} {props.title}
					</Button>
				) : (
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className={`${props.buttonClassName} ${
							props.wide
								? 'w-[300px] justify-between text-bw-green font-normal'
								: 'w-[200px] justify-between text-bw-green font-normal'
						}`}
					>
						<span className={!value ? 'opacity-30' : 'whitespace-nowrap text-ellipsis'}>
							{value ? (
								<span className={props.capitalise ? 'capitalize' : ''}>
									{props.items.find((item: Item) => item.value === value)?.name}
								</span>
							) : (
								props.title
							)}
						</span>
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				)}
			</PopoverTrigger>
			<PopoverContent className={`${props.wide ? `w-[300px] p-0` : 'w-[200px] p-0'}`}>
				<Command>
					{/* <CommandInput
           placeholder={`Search ${props.title.toLowerCase()}`} 
          /> */}
					<Input
						placeholder={`Search ${props.title.toLowerCase()}`}
						value={searchTerm}
						onChange={(event) => setSearchTerm(event.target.value)}
					/>
					{/* <CommandEmpty>No {props.title.toLowerCase()} found.</CommandEmpty> */}
					<CommandGroup>
						<div className="max-h-[300px] overflow-scroll">
							{props.items
								.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLowerCase()))
								.map((item: Item) => (
									<CommandItem
										key={item.value}
										value={item.value}
										onSelect={(currentValue) => {
											const newValue: any = currentValue === value ? '' : currentValue;
											setValue(newValue);
											props.selectCallback(newValue);
											setOpen(false);
										}}
									>
										<span className={props.capitalise ? 'capitalize' : ''}>{item.name}</span>
										<Check className={cn('h-4 w-4', value === item.value ? 'opacity-100' : 'opacity-0')} />
									</CommandItem>
								))}
						</div>
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
}

export default Combobox