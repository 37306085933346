// src/components/ProductParts.tsx

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@/global-components/components/ui/button';
import { Input } from '@/global-components/components/ui/input';
import { Checkbox } from '@/global-components/components/ui/checkbox';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { ChevronRight, ChevronDown, Plus, Loader, XCircle, Check, X, Boxes, Link } from 'lucide-react';
import { Separator } from '@radix-ui/react-select';
import _ from 'lodash';

import api from '@/api/bw-api';
import helpers from '@/global-components/components/helpers';
import { PartType, FileType, ProductType, User } from '@/global-components/types';
import { useToast } from '@/global-components/components/ui/use-toast';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/global-components/components/ui/collapsible';
import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo';
import FileInfo from '@/components/productRelated/FileInfo';
import Dropdown from '@/global-components/components/dropdown/Dropdown';
import Combobox from '@/global-components/components/combobox/Combobox';
import FileTag from '@/global-components/components/bw/FileTag';

type ProductPartsProps = {
  product: ProductType;
  parts: PartType[];
  linkedParts: PartType[];
  userParts: PartType[];
  files: FileType[];
  unassignedFiles: FileType[];
  latestVersion: boolean;
  viewFileById?: Function;
  refetchParts: () => void;
};

const ProductParts: React.FC<ProductPartsProps> = ({
  product,
  parts,
  linkedParts,
  userParts,
  files,
  unassignedFiles,
  latestVersion,
  viewFileById,
  refetchParts,
}) => {
  const { toast } = useToast();

  // State variables
  const [createNewPartFormVisible, setCreateNewPartFormVisible] = useState<boolean>(false);
  const [newPartFiles, setNewPartFiles] = useState<FileType[]>([]);
  const [newPartName, setNewPartName] = useState<string | undefined>(undefined);
  const [partsBeingDeleted, setPartsBeingDeleted] = useState<string[]>([]);
  const [partsBeingUpdated, setPartsBeingUpdated] = useState<string[]>([]);
  const [filesBeingRemoved, setFilesBeingRemoved] = useState<string[]>([]);
  const [partAddFilePopoverOpen, setPartAddFilePopoverOpen] = useState<string | undefined>(undefined);
  const [filesToAddToPart, setFilesToAddToPart] = useState<FileType[]>([]);
  const [partNameBuffer, setPartNameBuffer] = useState<string | undefined>(undefined);
  const [partNameFocused, setPartNameFocused] = useState<string | undefined>(undefined);

  // Mutations
  const [createPartMutation, { loading: creatingPart }] = useMutation(api.products.mutations.CREATE_PART_FOR_PRODUCT);
  const [deletePartMutation, { loading: deletingPart }] = useMutation(api.products.mutations.DELETE_PART);
  const [updatePartMutation, { loading: updatingPart }] = useMutation(api.products.mutations.UPDATE_PART);
  const [createProductPartLinkMutation, { loading: linkingPart }] = useMutation(api.products.mutations.CREATE_PRODUCT_PART_LINK)

  // Functions
  const createPart = () => {
    const fileIds: string[] = newPartFiles.map((file) => file.fileId);
    createPartMutation({
      variables: { productId: product.productId, fileIds: fileIds, partName: newPartName },
    })
      .then((result) => {
        console.log(result);
        setNewPartFiles([]);
        setNewPartName(undefined);
        setCreateNewPartFormVisible(false);
        toast({
          title: 'Part Created',
          variant: 'success',
          duration: 3000,
        });
        refetchParts();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: 'Part Creation Failed',
          variant: 'destructive',
          duration: 3000,
        });
      });
  };

  const onPartNameBlur = (part: PartType, newName: string) => {
    if (partNameBuffer === part.partName) {
      setPartNameBuffer(undefined);
      setPartNameFocused(undefined);
      return;
    }
    setPartsBeingUpdated([...partsBeingUpdated, part.partId]);
    updatePartMutation({ variables: { partId: part.partId, partName: newName } })
      .then((result) => {
        refetchParts();
        setPartNameBuffer(undefined);
        setPartNameFocused(undefined);
        toast({
          title: 'Updated part name',
          variant: 'success',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      })
      .catch((error) => {
        setPartNameBuffer(undefined);
        setPartNameFocused(undefined);
        toast({
          title: 'Updating part failed',
          variant: 'destructive',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      });
  };

  const addFilesToPart = (partId: string) => {
    setPartsBeingUpdated([...partsBeingUpdated, partId]);
    const fileIds: string[] = filesToAddToPart.map((file) => file.fileId);
    updatePartMutation({ variables: { partId: partId, addFileIds: fileIds } })
      .then((result) => {
        refetchParts();
        toast({
          title: 'Files added to part',
          variant: 'success',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== partId));
      })
      .catch((error) => {
        toast({
          title: 'Updating part failed',
          variant: 'destructive',
          duration: 3000,
        });
        console.log(error);
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== partId));
      });
  };

  const removeFileFromPart = (partId: string, fileId: string) => {
    setFilesBeingRemoved([...filesBeingRemoved, fileId]);
    updatePartMutation({ variables: { partId: partId, removeFileIds: [fileId] } })
      .then((result) => {
        refetchParts();
        setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
        toast({
          title: 'Files have been removed from part',
          variant: 'success',
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Removing files failed',
          variant: 'destructive',
          duration: 5000,
        });
        console.log(error);
        setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
      });
  };

  const deletePart = (id: string) => {
    setPartsBeingDeleted([...partsBeingDeleted, id]);
    deletePartMutation({ variables: { partId: id } })
      .then((result) => {
        refetchParts();
        toast({
          title: 'Part Deleted',
          variant: 'success',
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Deleting part failed',
          variant: 'destructive',
          duration: 3000,
        });
        console.log(error);
      })
      .finally(() => {
        setPartsBeingDeleted(partsBeingDeleted.filter((partId) => partId !== id));
      });
  };

  const cancelNewPart = () => {
    setNewPartName(undefined);
    setNewPartFiles([]);
    setCreateNewPartFormVisible(false);
  };

  const createProductPartLink = (partId: string) => {
		createProductPartLinkMutation({ variables: { productId: product.productId, partId: partId }})
			.then((result) => {
				if (result.data.createProductPartLink.success) {
					toast({
						title: "Part linked successfully",
						variant: 'success',
						duration: 3000,
					});
          refetchParts()
				} else {
					toast({
						title: "Lik failed",
						variant: 'destructive',
						description: 'Something went wrong',
						duration: 5000
					});
				}
			})
	}

  return (
    <div className="parts flex flex-col gap-2 text-sm">
      {parts.map((part: PartType, index: number) => (
        <div
          key={part.partId}
          className="part flex flex-col gap-2 p-2 border border-bw-green/10 bg-white/70 rounded-xl group/part hover:border-bw-green/20"
        >
          <div className="flex items-center justify-between gap-2 font-medium">
            <Input
              className={`border-transparent bg-transparent hover:border-bw-green/10 focus:border ${
                partsBeingUpdated.includes(part.partId) && 'opacity-30 pointer-events-none'
              } ${!latestVersion && 'opacity-100 pointer-events-none'}`}
              value={
                partNameBuffer !== undefined && partNameFocused === part.partId
                  ? partNameBuffer
                  : part.partName
              }
              onChange={(e) => setPartNameBuffer(e.target.value)}
              onFocus={() => {
                setPartNameFocused(part.partId);
                setPartNameBuffer(part.partName);
              }}
              onBlur={(e) => onPartNameBlur(part, e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
            />

            {partsBeingDeleted.includes(part.partId) ? (
              <Button
                variant="destructiveminimal"
                disabled
                className="text-ui-denial-red flex gap-1 items-center whitespace-nowrap"
                onClick={() => deletePart(part.partId)}
              >
                <Loader className="h-4 w-4 animate-spin" /> Deleting
              </Button>
            ) : (
              <Button
                variant="destructiveminimal"
                className={`text-ui-denial-red flex gap-1 items-center whitespace-nowrap opacity-0 group-hover/part:opacity-100 ${
                  !latestVersion && 'opacity-0 group-hover/part:opacity-0 pointer-events-none'
                }`}
                onClick={() => deletePart(part.partId)}
              >
                Delete part
              </Button>
            )}

            <Popover
              open={partAddFilePopoverOpen === part.partId}
              onOpenChange={() => {
                if (partAddFilePopoverOpen === undefined) {
                  setPartAddFilePopoverOpen(part.partId);
                } else {
                  setPartAddFilePopoverOpen(undefined);
                  setFilesToAddToPart([]);
                }
              }}
            >
              <PopoverTrigger asChild>
                <Button
                  variant="confirmoutline"
                  title="Add file to part"
                  size="icon"
                  disabled={partsBeingUpdated.includes(part.partId)}
                  className={`flex items-center ${
                    partAddFilePopoverOpen === part.partId ? 'opacity-100' : 'opacity-0'
                  } group-hover/part:opacity-100 ${
                    partsBeingUpdated.includes(part.partId) ? 'group-hover/part:opacity-30' : ''
                  } ${!latestVersion && 'opacity-0 group-hover/part:opacity-0 pointer-events-none'}`}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="max-w-96 bg-white p-6 rounded-xl shadow-2xl z-30" align="end">
                <div className="flex flex-col gap-3">
                  <div className="font-bold text-sm">Selected files will be added to part</div>
                  {unassignedFiles.map((unassignedFile: FileType) => (
                    <div key={unassignedFile.fileId} className="flex items-center gap-2 font-mono font-normal">
                      <Checkbox
                        id={unassignedFile.fileId}
                        checked={
                          filesToAddToPart.some((file) => unassignedFile.fileId === file.fileId)
                            ? true
                            : false
                        }
                        onCheckedChange={(checked: boolean) => {
                          if (checked) {
                            setFilesToAddToPart([...filesToAddToPart, unassignedFile]);
                          } else {
                            setFilesToAddToPart(
                              filesToAddToPart.filter((file) => file.fileId !== unassignedFile.fileId)
                            );
                          }
                        }}
                      />
                      <label
                        className="flex items-center gap-1 justify-between"
                        htmlFor={unassignedFile.fileId}
                      >
                        {/* Assuming FileTag is a component that displays file type */}
                        <FileTag type={helpers.extractFileTypeFromFilename(unassignedFile.fileName) as any} />
                        {unassignedFile.fileName}
                      </label>
                    </div>
                  ))}

                  <div className="flex gap-1 mt-2">
                    <Button
                      variant="destructiveoutline"
                      onClick={() => {
                        setFilesToAddToPart([]);
                        setPartAddFilePopoverOpen(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="bwconfirm"
                      className="grow"
                      disabled={filesToAddToPart.length === 0}
                      onClick={() => {
                        addFilesToPart(part.partId);
                        setPartAddFilePopoverOpen(undefined);
                      }}
                    >
                      Add files
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>

          {/* Display Gcode Files */}
          {part.files.filter(helpers.filters.isFileGcode)?.map((file: FileType, index: number) => (
            <div key={file.fileId} className="flex flex-col gap-1">
              <GcodeFileInfo
                product={product}
                updating={filesBeingRemoved.includes(file.fileId)}
                file={file}
                key={index}
                index={index}
                showActions={true}
                user={product.createdBy as User}
                showDetails
                inPart
                removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            </div>
          ))}

          {/* Display STL Files */}
          {part.files.filter(helpers.filters.isFileStl)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              inPart
              updating={filesBeingRemoved.includes(file.fileId)}
              product={product}
              removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
              viewOnly={!latestVersion}
              view={(id: string) => viewFileById && viewFileById(id)}
            />
          ))}

          {/* Display Image Files */}
          {part.files.filter(helpers.filters.isFileImage)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              inPart
              previewImage
              updating={filesBeingRemoved.includes(file.fileId)}
              product={product}
              removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
              viewOnly={!latestVersion}
              view={(id: string) => viewFileById && viewFileById(id)}
            />
          ))}

          {/* Display Other Files */}
          {part.files.filter(helpers.filters.isOtherFile)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              product={product}
              callback={(id: string) => files.filter((file) => file.fileId !== id)}
              viewOnly={!latestVersion}
              view={(id: string) => viewFileById && viewFileById(id)}
            />
          ))}
        </div>
      ))}
      <div className={`text-xs font-bold text-bw-grey w-full text-center capitalize mt-2 ${linkedParts.length === 0 && 'hidden'}`}>
        LINKED FROM OTHER PRODUCT
      </div>
      {linkedParts.map((part: PartType) => {
        return (
          <div
            key={part.partId}
            className="part flex flex-col gap-2 p-2 border border-dashed border-bw-green/20 bg-white/70 rounded-xl group/part hover:border-bw-green/20"
          >
            <div className='font-medium text-bw-green p-3 flex items-center justify-between gap-1'>
              {part.partName}<Link className='h-4 w-4 opacity-30' />
            </div>
            {/* Display Gcode Files */}
            {part.files.filter(helpers.filters.isFileGcode)?.map((file: FileType, index: number) => (
              <div key={file.fileId} className="flex flex-col gap-1">
                <GcodeFileInfo
                  product={product}
                  updating={filesBeingRemoved.includes(file.fileId)}
                  file={file}
                  key={index}
                  index={index}
                  showActions={true}
                  user={product.createdBy as User}
                  showDetails
                  inPart
                  noEdit
                  removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
                  viewOnly={!latestVersion}
                  view={(id: string) => viewFileById && viewFileById(id)}
                />
              </div>
            ))}

            {/* Display STL Files */}
            {part.files.filter(helpers.filters.isFileStl)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                inPart
                noEdit
                updating={filesBeingRemoved.includes(file.fileId)}
                product={product}
                removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}

            {/* Display Image Files */}
            {part.files.filter(helpers.filters.isFileImage)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                inPart
                noEdit
                previewImage
                updating={filesBeingRemoved.includes(file.fileId)}
                product={product}
                removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}

            {/* Display Other Files */}
            {part.files.filter(helpers.filters.isOtherFile)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                noEdit
                product={product}
                callback={(id: string) => files.filter((file) => file.fileId !== id)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}
          </div>
        )
      })}

      {/* New Part Form */}
      {createNewPartFormVisible ? (
        <div className={`flex flex-col gap-1 ${parts.length && 'mt-2'}`}>
          <div className="border border-bw-grey rounded-xl bg-white/70 p-4 text-sm flex flex-col gap-4">
            <Input
              type="text"
              name="partName"
              placeholder="Name your new part (this can be changed later)"
              value={newPartName}
              onChange={(e) => setNewPartName(e.target.value)}
            />
            <div className="pl-3 flex flex-col gap-3 mb-2">
              <div className="font-bold text-sm">Selected files will be added to part</div>
              {unassignedFiles.map((unassignedFile: FileType) => (
                <div key={unassignedFile.fileId} className="flex items-center gap-2 font-mono">
                  <Checkbox
                    id={unassignedFile.fileId}
                    checked={
                      newPartFiles.some((file) => unassignedFile.fileId === file.fileId) ? true : false
                    }
                    onCheckedChange={(checked: boolean) => {
                      if (checked) {
                        setNewPartFiles([...newPartFiles, unassignedFile]);
                      } else {
                        setNewPartFiles(newPartFiles.filter((file) => file.fileId !== unassignedFile.fileId));
                      }
                    }}
                  />
                  <label className="flex items-center gap-1 justify-between" htmlFor={unassignedFile.fileId}>
                    <FileTag type={helpers.extractFileTypeFromFilename(unassignedFile.fileName) as any} />
                    {unassignedFile.fileName}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <Button variant="bwsecondary" disabled={creatingPart} onClick={() => cancelNewPart()}>
              Cancel
            </Button>
            <Button
              variant="bwconfirm"
              disabled={!newPartName || !newPartFiles.length || creatingPart}
              onClick={() => createPart()}
            >
              {creatingPart ? <Loader className="h-4 w-4 animate-spin" /> : <div>Save</div>}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex gap-1 items-center">
          <Button
            variant="bwprimary"
            onClick={() => setCreateNewPartFormVisible(true)}
            className={`w-min ${!latestVersion && 'hidden'} whitespace-nowrap ${(parts.length + linkedParts.length) && 'mt-2'}`}
          >
            <Plus className="h-4 w-4" /> Create New Part
          </Button>
          {userParts.length > 0 && (
            <Combobox
              title="Link existing part"
              titleIcon={<Link className='h-4 w-4' />}
              trigger
              items={userParts.map((part) => ({ name: part.partName, value: part.partId }))}
              value={null}
              buttonClassName={`w-min ${!latestVersion && 'hidden'} whitespace-nowrap ${(parts.length + linkedParts.length) && 'mt-2'}`}
              selectCallback={(partId: string) => createProductPartLink(partId)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductParts;
